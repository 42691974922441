.br-420px {
  display: none;
}

@media screen and (max-width: 420px) {
  .br-420px {
    display: block;
  }
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.w-25 {
  width: 25%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.cmw-50-5 {
  width: calc(50% - 5px);
}

.cmw-50-10 {
  width: calc(50% - 10px);
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.w-100 {
  width: 100%;
}

.d-inline-block {
  display: inline-block;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-10 {
  margin-bottom: 10px;
}

.col-centered {
  max-width: 1136px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  align-items: center;
}

.flex-end {
  align-items: flex-end;
}

.text-middle {
  margin: auto 0;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-center {
  align-items: center;
}

.url-partial {
  //
}

.no-wrap {
  white-space: nowrap;
}

.bold {
  font-weight: bold;
}
