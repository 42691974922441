.tabs {
  .tab {
    text-decoration: none;
    position: relative;
    cursor: pointer;
    background-color: #78003F;
    width: calc(33.33% - 1px);
    border-right: 1px solid #ffffff54;

    &.active {
      background-color: #E64164;

      &:before {
        background-image: linear-gradient(45deg, #E64164 50%, transparent 50%);
        position: absolute;
        left: 50%;
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        border-bottom: 2px solid #E64164;
        border-left: 2px solid #E64164;
        transform: translateY(37px) rotate(-45deg);
        z-index: 9;
      }
    }

    .tab-text {
      text-align: center;
      padding: 12px;
      font-weight: 600;
      color: white;
    }
  }
}
