footer {
  background-color: #78003F;
  width: 100%;
  position: absolute;
  bottom: 0;

  .copyright {
    padding: 30px 0;
    font-weight: 400;
    font-size: 13px;
    color: white;
  }
}
