.form-container {
  background-color: #F5F5F5;
  padding: 40px;
  border: 1px solid #E5E5E5;

  &.empty-container {
    background-color: unset;
    padding: 0;
    border: none;
  }

  .loader-balls {
    display: none;
    text-align: center;

    .ball {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 999;
    }

    .ball-one {
      background-color: #FFCE64;
      animation: jump .5s ease 0s infinite alternate;
    }

    .ball-two {
      background-color: #4BB3EF;
      animation: jump .5s ease .15s infinite alternate;
    }

    .ball-three {
      background-color: #63DD79;
      animation: jump .5s ease .25s infinite alternate;
    }

    @keyframes jump {
      100% {
        transform: translateY(-10px);
      }
    }
  }

  .dynamic-form {
    &.loading {
      .submit-button {
        &:not(.ignore-loading) {
          .submit-text {
            display: none;
          }

          .loader-balls {
            display: inline;
          }
        }
      }
    }
  }

  .added-file {
    font-weight: 400;
    font-size: 15px;
    padding-bottom: 10px;
  }

  .file-remover {
    color: #E64164;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .file-template {
    display: none;
  }

  .ajax-message {
    &.fail {
      padding-top: 30px;
      color: #E64164;
    }
    &.success {
      padding-top: 30px;
      color: green;
    }
  }

  .custom-clickable-message {
    padding-top: 30px;
    cursor: pointer;
    display: none;

    &.show {
      display: block;
    }

    &:hover {
      text-decoration: underline;
      color: #E64164;
    }
  }

  .form-field {
    display: flex;
    flex-direction: column;
    padding-top: 15px;

    &.has-error {
      color: #E64164;

      input {
        border: 1px solid #E64164;
      }

      select {
        border: 1px solid #E5E5E5;
      }
    }

    &.date-input {
      position: relative;

      .date-wrapper {
        display: contents;

        //&:after {
        //  content: '';
        //  position: absolute;
        //  width: 15px;
        //  height: 18px;
        //  background: url("../images/icons/calendar.svg") 0 center no-repeat;
        //  background-size: 15px;
        //  bottom: 19%;
        //  right: 24px;
        //}
      }
    }

    .label-text {
      font-weight: 400;
      font-size: 13px;
      padding-bottom: 5px;
    }

    .field-error {
      display: none;
      font-weight: 400;
      font-size: 13px;
      padding-bottom: 5px;
      color: #E64164;

      &.show {
        display: flex;
      }
    }

    select {
      padding: 15px 20px;
      border: 1px solid #E5E5E5;
      border-radius: 5px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: white;
        cursor: not-allowed;
      }
    }

    input {
      padding: 15px 20px;
      border: 1px solid #E5E5E5;
      border-radius: 5px;

      &:focus {
        outline: none;
      }

      &:disabled {
        background-color: white;
        cursor: not-allowed;
      }
    }

    &.field-file {
      padding-top: 30px;
      padding-bottom: 20px;

      &.has-error {
        .label-text {
          border: 1px solid #E64164;
        }
      }

      .label-text {
        padding: 13px 20px;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        background-color: #EEEEEE;
        cursor: pointer;
        margin-top: 5px;
      }
    }

    .file-input {
      display: none;
    }

  }

  .line {
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .checkbox-link {
    color: #78003F;
  }

  .checkbox-container {
    max-width: 600px;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 0;

    &.has-error {
      .checkmark {
        border: 1px solid #E64164;
      }
    }

    .label-text {
      font-size: 13px;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~ {
          .checkmark {
            background-color: #78003F;
            &:after {
              display: block;
            }
          }
        }
      }
    }

    .checkmark {
      &:after {
        left: 7px;
        top: 2.9px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #E5E5E5;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  .submit-button {
    margin-top: 30px;
    font-weight: 800;
    font-size: 14px;
    color: #ffffff;
    border: none;
    background-color: #78003F;
    cursor: pointer;
    padding: 15px 40px;
    text-transform: uppercase;

    &:hover {
      background-color: #E64164;
    }

    &.inversed {
      background-color: transparent;
      border: 3px solid #78003F;
      color: #78003F;

      &:hover {
        border: 3px solid #E64164;
        color: #E64164;
      }
    }
  }
}
