.tippy-popper[x-placement^=right] .tippy-arrow {
  border-right: 8px solid white !important;
}

.tippy-popper[x-placement^=left] .tippy-arrow {
  border-left: 8px solid white !important;
}

.tippy-tooltip {
  background-color: white !important;
  color: black !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1), 0 10px 40px rgba(0, 0, 0, 0.1);
  padding: 0 !important;

  .tippy-content {
    max-width: 210px !important;
    text-align: left !important;
    padding: 15px !important;
    padding-bottom: 10px !important;
    min-width: 200px !important;
  }

  .tooltip-title {
    font-size: 16px !important;
    font-weight: 600 !important;
    padding-bottom: 5px !important;
    text-transform: uppercase;
  }

  .tooltip-description {
    font-size: 11px !important;
    font-weight: 400 !important;
  }

  .tooltip-datetime {
    padding: 10px 0;
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .tooltip-not-allowed {
    padding: 10px 0;
    font-size: 12px !important;
    font-weight: 600 !important;
    color: red;
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .tooltip-button {
    padding: 15px 10px;
    width: 100%;
    background-color: #78003F;
    color: white;
    text-decoration: none;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #E64164;
    }
  }
}

.timetable-block {
  overflow: hidden;
  position: relative;
  padding-bottom: 200px;

  .week-day-selector {
    display: none;
  }

  .background-decoration {
    position: absolute;
    right: -70px;
    bottom: -40px;
    width: 600px;
  }

  .timetable-tab-content {
    padding-top: 25px;

    &.active {
      display: block;
    }

    .timetable-holder {
      margin-top: 40px;
      position: relative;

      .header-th {
        width: 14%;
      }

      #mobile_tt_previous {
        display: none;
        position: absolute;
        left: 30px;
        top: 20px;
      }

      #mobile_tt_next {
        display: none;
        position: absolute;
        right: -30px;
        top: 20px;
      }

      .table-header {
        margin-bottom: 10px;
        padding: 14px 20px;
        background-color: #F9F3EB;
        border: 1px solid #EEE5D8;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
      }

      .timetable-cell {
        vertical-align: top;
        border-radius: 5px;

        &.empty {
          background-color: #F5F5F5;
        }
      }

      .table-item {
        outline: none;
        position: relative;
        margin-bottom: 5px;
        padding: 10px 20px;
        background-color: #F5F5F5;
        border-radius: 5px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        min-height: 37px;

        &.time {
          border: 1px solid #EEE5D8;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 400;
          background-color: #F9F3EB;
          display: table-cell;
        }

        .price-tag {
          position: absolute;
          left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          border-radius: 5px 0 0 0;
          background-image: linear-gradient(135deg, #E64164 50%, transparent 50%);

          .price-icon {
            position: absolute;
            top: 3px;
            left: 3px;
          }
        }

        .item-title {
          font-size: 16px;
          color: #ffffff;
          font-weight: 600;
          text-align: center;
          padding-bottom: 5px;
        }

        .item-coach {
          font-size: 11px;
          color: #ffffff;
          font-weight: 400;
          text-align: center;
          padding-bottom: 1px;
        }

        .item-free-space {
          font-size: 11px;
          color: #ffffff;
          font-weight: 400;
          text-align: center;

          .space-text {
            font-weight: 700;
          }
        }

        .item-registered {
          margin-left: -20px;
          margin-right: -20px;
          margin-top: 10px;
          text-align: center;
          padding: 5px 20px;
          font-size: 11px;
          color: #ffffff;
          font-weight: 400;
          background-color: #00000033;
        }
      }
    }

    .type-date-holder {
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-wrap: wrap;

      .type-selector {
        .label-text {
          font-size: 13px;
          font-weight: 400;
        }

        select {
          padding: 11px 20px;
          margin-left: 11px;
          border: 1px solid #E5E5E5;
          border-radius: 5px;
          background-color: #F5F5F5;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }

      .date-selector {
        font-size: 18px;
        font-weight: 700;
        margin: auto 0;

        .arrow-left {
          padding-right: 20px;
          cursor: pointer;
        }

        .arrow-right {
          padding-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
