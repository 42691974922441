@font-face {
  font-family: 'Raleway';
  src: local('Raleway Black Italic'), local('Raleway-BlackItalic'),
  url('./Raleway-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
  url('./Raleway-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
  url('./Raleway-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Bold'), local('Raleway-Bold'),
  url('./Raleway-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
  url('./Raleway-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
  url('./Raleway-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
  url('./Raleway-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
  url('./Raleway-ExtraBoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Medium'), local('Raleway-Medium'),
  url('./Raleway-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Thin'), local('Raleway-Thin'),
  url('./Raleway-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
  url('./Raleway-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
  url('./Raleway-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
  url('./Raleway-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Thin Italic'), local('Raleway-ThinItalic'),
  url('./Raleway-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Regular'), local('Raleway-Regular'),
  url('./Raleway-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Black'), local('Raleway-Black'),
  url('./Raleway-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Italic'), local('Raleway-Italic'),
  url('./Raleway-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Light'), local('Raleway-Light'),
  url('./Raleway-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

