#additional_template {
  display: none;
}

.hall-additional-remove {
  width: 25px;
  margin: auto 0px auto 15px;
  cursor: pointer;
  transform: translateY(20px);
}

.hall-additional-add {
  width: 25px;
  cursor: pointer;
  transform: translate(5px, 20px) rotate(45deg);
}

.add-additional-text {
  padding-left: 50px;
  transform: translateY(-5px);
}

.add-additional-holder {
  cursor: pointer;
}

.halls-block {
  overflow: hidden;
  position: relative;
  padding-bottom: 200px;

  .background-decoration {
    position: absolute;
    right: -70px;
    bottom: -40px;
    width: 600px;
  }

  .halls-tab-content {
    padding-top: 25px;

    &.active {
        display: block;
    }

    .club-info {
      .info-block {
        padding-top: 25px;
        width: 33.33%;

        .title-text {
          padding-right: 30px;
          font-size: 16px;
          font-weight: 600;
          color: #78003F;
        }

        .info-address {
          font-size: 16px;
        }

        .info-address-note {
          font-size: 13px;
        }

        .map-marker-holder {
          padding-top: 15px;

          .map-marker {
            padding-right: 5px;
          }

          .info-address-map-link {
            text-decoration: none;
            font-size: 16px;
            color: #78003F;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .halls-cards {
      min-height: 350px;
      padding-top: 60px;

      .card {
        position: relative;
        border: 1px solid #E5E5E5;
        background-color: #F5F5F5;
        width: calc(33.33% - 22px);
        margin-bottom: 20px;
        margin-right: 20px;

        &:hover {
          background-color: white;
          box-shadow: 0 9px 20px 1px #00000036;
        }

        .thumbnail {
          width: 100%;
        }

        .lower-content {
          padding: 35px 35px 145px;

          .halls-title {
            font-size: 24px;
            color: #78003F;
            font-weight: 800;
            padding-bottom: 27px;
          }

          .halls-feature {
            padding-bottom: 10px;
            padding-right: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #E5E5E5;

            &.no-border {
              border-bottom: none;
            }

            .feature-img {
              padding-right: 10px;
              align-self: flex-start;
            }
          }

          .halls-discount-price {
            padding: 20px 0;

            .discount {
              font-weight: 800;
              font-size: 24px;
              color: #E64164;
            }

            .original {
              font-weight: 600;
              font-size: 16px;
              color: black;
              text-decoration: line-through;
            }
          }

          .buy-holder {

            .halls-price {
              padding: 20px 0;
              font-weight: 800;
              font-size: 24px;
              color: black;
              position: absolute;
              bottom: 45px;
            }

            position: absolute;
            bottom: 35px;
            margin-right: 35px;
          }
        }
      }

      .order-btn {
        padding: 15px 40px;
        background-color: #78003F;
        color: white;
        text-decoration: none;
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #E64164;
        }
      }
    }
  }
}
