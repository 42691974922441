.btn-link {
  text-decoration: none;
  color: black;

  &.active {
    color: #E64164;
    border-bottom: 2px solid #E64164;
    padding-top: 6px;
    padding-bottom: 4px;
  }

  &:hover {
    color: #E64164;
    border-bottom: 2px solid #E64164;
    padding-top: 6px;
    padding-bottom: 4px;
  }
}

.sso-link {
  display: inline-block;
  color: white;
  padding: 15px 40px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #78003F;
  font-weight: 800;
  font-size: 14px;

  &:hover {
    background-color: #E64164;
  }
}


.order-hall-btn {
  display: inline-block;
  color: white;
  padding: 15px 40px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #78003F;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #E64164;
  }
}

.back-btn {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  color: #78003F;
  padding-top: 50px;

  &:hover {
    color: #E64164;
    text-decoration: underline;
  }
}
