.visibility-tab-content {
  display: none !important;

  &.active {
    display: block !important;
  }
}

.registration-block {
  position: relative;
  padding-bottom: 200px;
  overflow: hidden;

  .choosing-blocks {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;

    .choosing-block {
      cursor: pointer;
      padding: 30px 20px 40px 20px;
      background-color: #F5F5F5;
      text-align: center;
      border: 1px solid #E5E5E5;
      z-index: 9;
      width: calc(33% - 50px);

      .form-container {
        padding: 0;
        border: none;
      }

      &:hover {
        outline: 3px solid #78003F;
      }

      &.active {
        outline: 3px solid #78003F;
      }

      h3 {
        color: #78003F;
      }

      img {
        transform: rotate(180deg);
      }
    }
  }

  .registration-form {
    position: relative;
    display: block;
    //padding-top: 0;

    .dynamic-form {
      display: none;

      &.active {
        display: block;
      }
    }

    .authorization-tabs {
      background-color: #E5E5E5;
      margin-left: -40px;
      margin-right: -40px;
      padding: 0 40px;
      margin-bottom: 20px;

      .authorization-tab {
        display: inline-block;
        cursor: pointer;
        font-size: 18px;
        font-weight: 700;
        margin-right: 30px;
        padding-bottom: 30px;
        padding-top: 30px;
        user-select: none;

        &.active {
          color: #E64164;
          border-bottom: 3px solid #E64164;
          padding-bottom: 27px;
        }

        &:hover {
          color: #E64164;
          border-bottom: 3px solid #E64164;
          padding-bottom: 27px;
        }
      }
    }
  }

  .background-decoration {
    position: absolute;
    right: -130px;
    bottom: 0;
    width: 700px;
  }

  .field-50 {
    width: 325px;
  }

  .field-25 {
    width: 158px;
  }

  .field-file {
    .label-text {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #d8d7d7;
      }

      .attachment-icon {
        padding-right: 5px;
      }
    }
  }
}
