.group-activities-block {
  overflow: hidden;
  position: relative;
  padding-bottom: 200px;

  .background-decoration {
    position: absolute;
    right: -70px;
    bottom: -40px;
    width: 600px;
  }

  .group-content {
    padding-top: 25px;

    img {
      position: relative;
    }

    .group-items {
      min-height: 350px;
      padding-top: 60px;

      .additional-items-title {
        padding-top: 50px;
        font-size: 24px;
        color: #78003F;
        font-weight: 800;
        padding-bottom: 27px;
      }

      &.additional-items {
        padding-top: 0;
      }

      .card {
        text-decoration: none;
        position: relative;
        border: 1px solid #E5E5E5;
        background-color: #F5F5F5;
        width: calc(33.33% - 22px);
        margin-bottom: 20px;
        margin-right: 20px;

        &:hover {
          background-color: white;
          box-shadow: 0 9px 20px 1px #00000036;
        }

        .lower-content {
          padding: 35px 35px 105px;

          .title {
            font-size: 24px;
            color: #78003F;
            font-weight: 800;
            padding-bottom: 27px;
          }

          .description {
            color: #0c1021;
          }

          .service-price {
            padding: 20px 0;
            font-weight: 800;
            font-size: 24px;
            color: black;
            position: absolute;
            bottom: 15px;
          }
        }

        .thumbnail {
          width: 100%;
        }
      }
    }
  }
}
