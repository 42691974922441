html {
  height: 100%;
  margin: 0;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: "Raleway",sans-serif;
  position: relative;
}

.container {
  max-width: 1230px;
  margin-right: auto;
  margin-left: auto;
}

h1 {
  font-weight: 800;
  font-size: 36px;
  color: #78003F;
  margin-top: 30px;
  margin-bottom: 30px;
}
