.modal-visible {
  overflow: hidden;
}

.modal {
  opacity: 0.1;
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  outline: 0;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-overflow-scrolling: touch;
}

.modal-close-ico {
  appearance: none;
  border: none;
  padding: 25px 8px;
  cursor: pointer;
  font-size: 66px;
  background-color: #f9f9f9;
  color: #000000;
  line-height: 0;
  font-weight: 300;
}

.modal-close-fancy {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  transform: translate(50%, -50%);
  color: #ffffff;
  background-color: #333333;
}

.modal-scroll-container {
  overflow: auto;
  height: 100%;
}

.modal-dialog {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: auto;
  margin: 20vh auto;
  padding: 0 15px;
}

.modal-content {
  position: relative;
  outline: 0;
  background-color: #f9f9f9;
  background-clip: padding-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

.modal-header {
  * {
    text-align: left;
  }
}

.modal-footer {
  margin-top: 20px;
}
