@media screen and (max-width: 1230px) {
  .container {
    padding: 0 20px;
  }

  .tabs {
    margin: 0 -20px;
  }

  header {
    .header-desktop-view {
      display: none;
    }

    .header-mobile-view {
      display: flex;

      .btn-link.active {
        border-bottom: none !important;
      }
    }
  }

  .client-button-text {
    display: none;
  }

  .header-languages {
    transform: translateX(60%);
  }
}

@media screen and (max-width: 984px) {
  .client-info-holder {
    flex-direction: column;
  }

  .mobile-show-bday {
    display: block !important;
  }

  .mobile-hide-bday {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .group-activities-block {
    .group-content {
      .group-items {
        .card {
          width: calc(50% - 12px);

          &:nth-child(even) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .memberships-block {
    .membership-tab-content {
      .membership-cards {
        .card {
          width: calc(50% - 82px);

          &:nth-child(even) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .halls-block {
    .tab-content {
      .halls-cards {
        .card {
          width: calc(50% - 12px);

          &:nth-child(even) {
            margin-right: 0;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {

  .registration-block {
    .field-50 {
      width: initial;
    }

    .field-25 {
      width: initial;
    }

    .flex {
      flex-direction: column;
    }
  }

  .form-container {
    .form-field {
      padding-right: 0;
    }
  }

  .timetable-block {
    overflow: hidden;
    position: relative;
    padding-bottom: 200px;

    .week-day-selector {
      display: flex !important;
    }

    .timetable-holder {
      margin-top: 10px !important;
    }
  }

  .fixed-cart {
    z-index: 9;
    bottom: 10%;
    right: 0;
  }


  .reg-hall {
    display: block !important;
  }

  .line {
    &.only-mobile {
      display: block !important;
    }
  }

  .client-zone-block .memberships {
    width: 100%;
  }

  .client-info-holder {
    display: block;
  }

  .absolute-title {
    position: absolute !important;
    top: 40px !important;
  }

  .remove-pr-mobile {
    padding-right: 0;
  }

  .client-zone-block .info-form .info-save {
    width: 100%;
  }

  .submit-info-holder {
    display: block;
  }

  .client-zone-block {

    .main-info-holder {
      padding-top: 40px !important;
      flex-direction: column-reverse;
    }

    .mobile-show-bday {
      display: block !important;
    }

    .mobile-hide-bday {
      display: none !important;
    }

    .form-container {
      .form-field {
        width: 100% !important;
      }
    }
  }

  .client-zone-block .info-form .photo-holder {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .client-zone-block .registrations-list .registration {
    display: block;
    margin-bottom: 10px;

    &.halls-reg {
      display: flex;
    }

    .reg-item {
      width: auto;
      text-align: center;
      padding-bottom: 10px;
    }

    .cancel-button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 720px) {
  .memberships-block {
    .membership-tabs {
      .membership-tab {
        width: calc(100% - 1px);
      }
    }

    .membership-tab-content {
      .club-info {
        .info-block {
          width: 100%;

          .title-text {
            min-width: 75px;
          }
        }

        .last-info-block {
          padding-left: 105px;
        }
      }
    }
  }

  .halls-block {
    .hall-tabs {
      .hall-tab {
        width: calc(100% - 1px);
      }
    }

    .tab-content {
      .club-info {
        .info-block {
          width: 100%;

          .title-text {
            min-width: 75px;
          }
        }

        .last-info-block {
          padding-left: 105px;
        }
      }
    }
  }
}

@media screen and (max-width: 685px) {
  .type-date-holder {
    justify-content: center !important;

    .type-selector {
      margin-bottom: 20px !important;
    }
  }
}

@media screen and (max-width: 575px) {
  .group-activities-block {
    .group-content {
      .group-items {
        .card {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  .memberships-block {
    .membership-tab-content {
      .membership-cards {
        .card {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  .halls-block {
    .tab-content {
      .halls-cards {
        .card {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  .modal-auth-login {
    .modal-content {
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 470px) {

  .timetable-block {
    .timetable-tab-content {
      .timetable-holder {

        .table-item {
          padding: 10px 10px;

          .item-registered {
            margin-left: -10px;
            margin-right: -10px;
          }
        }

        .header-th {
          .table-header {
            font-size: 12px;
            padding: 14px 10px;
          }
        }
      }
    }
  }


  .header-mobile-view {
    .logo {
      a {
        img {
          width: 150px;
        }
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .tabs {
    .tab {
      &:before {
        content: none !important;
      }

      .tab-text {
        font-size: 11px;
      }
    }
  }
}

@media screen and (max-width: 437px) {
  .timetable-block {
    .timetable-tab-content {
      .type-date-holder {
        .date-selector {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 1157px) {
  .timetable-block {

    .tt-mobile-hidden {
      display: none !important;
    }

    .tt-mobile-show {
      display: table-cell !important;
    }

    .timetable-tab-content {
      .timetable-holder {
        margin-left: -20px;
        margin-right: 30px;

        #mobile_tt_previous {
          display: block;
        }

        #mobile_tt_next {
          display: block;
        }

        .table-item {
          &.time {
            padding: 10px 10px;
          }
        }
      }
    }
  }

  .header-th, .timetable-cell {
    width: 16%;
  }

  .week-day-7 {
    display: none;
  }
}

@media screen and (max-width: 1010px) {
  .header-th, .timetable-cell {
    width: 20%;
  }

  .week-day-7, .week-day-6 {
    display: none;
  }
}

@media screen and (max-width: 870px) {
  .header-th, .timetable-cell {
    width: 25%;
  }

  .week-day-7, .week-day-6, .week-day-5 {
    display: none;
  }

  .registration-block .choosing-blocks .choosing-block {
    width: calc(50% - 50px);
  }
}

@media screen and (max-width: 710px) {
  .header-th, .timetable-cell {
    width: 33%;
  }

  .week-day-7, .week-day-6, .week-day-5, .week-day-4 {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .header-th, .timetable-cell {
    width: 50%;
  }

  .week-day-7, .week-day-6, .week-day-5, .week-day-4, .week-day-3 {
    display: none;
  }

  .cart-container .product-info .product-title {
    padding-right: 0;
  }

  .registration-block .choosing-blocks .choosing-block {
    width: 100%;
  }
}
