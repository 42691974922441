.modal-auth-login {
  .modal-content {
    min-width: 460px;

    h2 {
      margin-top: 0;
      color: #78003F;

      br {
        display: none;
      }
    }
  }
}

.modal-auth-register {
  .modal-content {
    h2 {
      margin-top: 0;
      color: #78003F;

      br {
        display: none;
      }
    }
  }
}
