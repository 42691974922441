.fixed-cart {
  position: fixed;
  bottom: 50%;
  right: 20px;
  transform: translateY(50%);
  display: none;

  &.has-items {
    display: block;
  }

  .shopping-cart-toggle {
    cursor: pointer;
  }

  .amount-text {
    position: absolute;
    top: 11px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: 500;
    font-size: 23px;
  }
}

.cart-container {
  margin-left: 25px;
  display: none;
  z-index: 559;
  background-color: white;
  position: fixed;
  bottom: 40px;
  right: 30px;
  box-shadow: 0 9px 20px 1px #00000036;
  border-radius: 5px;
  padding: 20px;

  .close-container {
    position: absolute;
    top: -12px;
    right: 17px;
    background-color: #78003F;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    padding: 5px;
    color: white;
    font-weight: 600;
    font-size: smaller;
    cursor: pointer;

    &:hover {
      background-color: #E64164;
    }
  }

  &.show {
    display: block;
  }

  .checkout-btn {
    text-decoration: none;
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #78003F;
    color: white;
    padding: 10px 20px;

    &:hover {
      background-color: #E64164;
      cursor: pointer;
    }
  }

  .total-info {
    text-align: right;
    padding: 20px 140px 10px 10px;
  }

  .product-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .product-title {
      font-weight: 600;
      padding-right: 40px;
      margin: auto 0;
      min-width: 250px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .product-price {
      margin: auto 0 auto auto;
      padding: 5px 10px 5px 0;
    }

    .product-count-widget {
      border: 1px solid #E5E5E5;
      background-color: #F5F5F5;
      width: 50px;
      text-align: end;
      padding: 5px 5px 5px 10px;

      .product-count {
        vertical-align: middle;
      }

      .count-buttons {
        display: inline-block;
        padding: 5px;
        vertical-align: middle;

        .product-count-up {
          width: 10px;
          display: block;
          margin-bottom: 5px;
          cursor: pointer;
        }

        .product-count-down {
          width: 10px;
          display: block;
          cursor: pointer;
        }
      }
    }

    .product-price-overall {
      padding: 5px 10px;
      margin: auto 0;
      width: 80px;
      text-align: end;
      display: inline-block;
    }

    .product-remove {
      width: 20px;
      margin: auto 0;
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;

      img {
        width: 20px;
        vertical-align: middle;
      }

      .hover {
        display: none;
      }

      &:hover {
        .hover {
          display: inline-block;
        }
        .default {
          display: none;
        }
      }
    }
  }
}
