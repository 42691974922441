header {
  background-color: #F5F5F5;

  .header-mobile-view {
    display: none;
    width: 100%;

    .burger-controls {
      display: inline-flex;

      .burger-icon-holder {
        margin: auto 0;

        &.show {
          .burger-icon-open {
            display: none;
          }

          .burger-icon-close {
            display: block;
          }
        }

        .burger-icon-open {
          width: 40px;
          margin: auto 0 auto 30px;
          cursor: pointer;
        }

        .burger-icon-close {
          width: 35px;
          margin: auto 0 auto 35px;
          cursor: pointer;
          display: none;
        }
      }
    }

    .mobile-hamburger {
      display: none;
      padding-bottom: 20px;
      width: 100%;

      &.show {
        display: inline-flex;
      }
    }

    .header-link {
      width: 100%;
      text-align: center;
      padding-top: 10px;
      margin-bottom: 10px;
    }

    .header-block-link {
      width: 100%;
      padding: 0;
      border: none;
      margin-right: 15px;

      .block-link-holder {
        padding-bottom: 0;
      }
    }
  }

  .logo {
    padding: 16px 0;
    margin-right: auto;
    img {
      max-height: 80px;
    }
  }

  .header-link {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
  }

  .header-block-link {
    font-weight: 600;
    text-decoration: none;
    color: #78003F;
    display: inline-flex;
    border-right: 2px solid #E5E5E5;
    border-left: 2px solid #E5E5E5;
    padding: 0 20px;
    position: relative;

    &.no-left-border {
      border-left: none;
    }

    &.no-border {
      border: none;
    }

    .block-link-holder {
      font-weight: 600;
      text-decoration: none;
      color: #78003F;
      padding-bottom: 20px;
      text-align: center;
      margin: auto;

      .block-link-text {
        font-weight: 600;
        text-decoration: none;
        color: #78003F;
      }
    }

    .logout-link {
      position: absolute;
      text-align: center;
      text-decoration: none;
      font-size: 13px;
      color: black;
      transform: translateX(-50%);
      font-weight: 400;
      left: 50%;
      padding-top: 5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.header-languages-holder {
  position: relative;
  width: 95px;
  height: 40px;
  margin: auto;
  padding-bottom: 43px !important;
}

.header-languages {
  position: absolute;
  left: -15px;
  display: flex;
  flex-direction: column;
  list-style: none;
  text-transform: uppercase;
  padding: 0;
  width: 65px;
  border-radius: 4px;
  margin: 0;
  background: #F5F5F5;

  &:not(:hover) li {
    display: none;
  }

  &:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }

  a {
    transform: translateX(-3px);
    text-decoration: none;
    padding: 10px;
    display: block;
    position: relative;
    z-index: 10;
    text-align: center;
    font-weight: 600;
    color: #78003F;
  }

  li.active {
    order: -1;
    pointer-events: none;
    display: flex;
    flex-direction: column;

    a {
      transform: unset;
    }

    a:before{
      content: '';
      width: 18px;
      height: 18px;
      background-image: url("../images/icons/globe.svg");
      background-repeat: no-repeat;
      display: block;
      margin: auto;
      padding-bottom: 5px;
    }

    a:after{
      content: '';
      width: 10px;
      height: 10px;
      background-image: url("../images/icons/dropdown_icon.svg");
      background-repeat: no-repeat;
      display: inline-block;
      transform: translate(7px, 1px);
    }
  }

  li:not(.active){
    border-top: solid 1px #E5E5E5;
  }
}
