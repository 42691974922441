.client-zone-block {
  position: relative;
  padding-bottom: 200px;
  overflow: hidden;

  .info-form {
    margin-top: 50px;
    position: relative;
    display: block;

    .client-info {
      font-weight: 600;
      font-size: 16px;
      color: #78003F;
      margin-top: 0;
    }

    .photo-holder {
      width: 230px;
      height: 230px;

      .linePreloader{
        display: none;
        height:5px;
        background:linear-gradient(to right,#78003F,#78003F);
        background-color:#ccc;
        top:0;
        bottom:0;
        left:0;
        right:0;
        margin:auto;
        border-radius:4px;
        background-size:20%;
        background-repeat:repeat-y;
        background-position:-25% 0;
        animation:scroll 1.2s ease-in-out infinite;

        &.show {
          display: block;
        }
      }

      @keyframes scroll{
        50%{background-size:80%}
        100%{background-position:125% 0;}
      }

      .icon-holder {
        position: relative;
        width: inherit;

        .photo-icon {
          width: inherit;
        }
      }

      .photo-attach-icon {
        padding-right: 5px;
      }

      .photo-attach {
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        align-items: flex-end;

        .client-photo-label {
          cursor: pointer;

          .client-photo-input {
            display: none;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .photo-remove {
        font-size: 13px;
        font-weight: 400;
        color: #E64164;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .info-left-holder {
      width: 100%;
      padding-right: 50px;

      .change-info-msg-holder {
        margin-top: 30px;
        margin-left: 15px;

        .change-info-msg {
          padding: 15px;
          display: none;
          color: #65B07A;
          font-size: 14px;
          font-weight: 600;

          &.success {
            display: block;
          }

          &.fail {
            display: block;
            color: red;
          }
        }
      }
    }

    .name-field {
      width: 280px;
    }

    .surname-field {
      width: 280px;
    }

    .info-save {
      width: 300px;
    }
  }

  .background-decoration {
    position: absolute;
    right: -130px;
    bottom: 0;
    width: 700px;
  }

  .section-title {
    font-weight: 800;
    font-size: 24px;
    color: #78003F;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .memberships-visits-block {
    display: flex;
    padding-top: 20px;
  }

  .memberships {
    margin-right: 20px;

    .memberships-content {
      padding: 30px;
      background-color: #F5F5F5;
      border: 1px solid #E5E5E5;

      .membership {
        display: flex;

        .title {
          font-weight: 600;
          font-size: 16px;
          padding-right: 30px;
          width: 30%;
        }

        .date-note {
          .date {
            font-weight: 600;
            font-size: 16px;
          }

          .note {
            font-weight: 400;
            font-size: 13px;
          }
        }
      }
    }
  }

  .visits {
    .times {
      font-weight: 800;
      color: #78003F;
      font-size: 32px;
      padding-right: 2px;
    }

    .visits-content {
      padding: 38px;
      background-color: #F5F5F5;
      border: 1px solid #E5E5E5;
      display: flex;

      .times-week {
        padding-right: 30px;
        margin-right: 30px;
        border-right: 2px solid #E5E5E5;
        margin-top: -10px;
        margin-bottom: -10px;
      }

      .times-month {
        padding-right: 30px;
        margin-right: 30px;
        border-right: 2px solid #E5E5E5;
        margin-top: -10px;
        margin-bottom: -10px;
      }

      .times-overall {
        margin-top: -10px;
        margin-bottom: -10px;
      }
    }
  }

  .registrations-list {
    position: relative;

    .price-tag {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      background-image: linear-gradient(135deg, #E64164 50%, transparent 50%);

      .price-icon {
        position: absolute;
        top: 3px;
        left: 3px;
      }
    }

    .registration-holder{
      cursor: pointer;

      .reservation-clients {
        display: none;
        background-color: #F5F5F5;
      }

      &.show {
        margin: 20px 0;

        .reservation-clients {
          display: block;
        }
      }
    }

    .registration {
      background-color: #F5F5F5;
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border: 1px solid #E5E5E5;

      .registration-title {
        padding-left: 25px;
        margin: auto 0;
        font-size: 16px;
        font-weight: 600;
        width: 150px;
      }

      .registration-date {
        margin: auto 0;
        font-size: 16px;
        font-weight: 600;
        width: 350px;

        .calendar-icon {
          height: 16px;
          padding-right: 2px;
        }
      }

      .registration-club {
        margin: auto 0;
        font-size: 16px;
        font-weight: 600;

        .marker-icon {
          height: 16px;
          padding-right: 2px;
        }
      }

      .cancel-button {
        width: 250px;
        padding: 13px 23px;
        background-color: #999999;
        color: white;
        text-decoration: none;
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 800;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #E64164;
        }
      }
    }
  }

  .client-zone-tabs {
    .client-zone-tab {
      text-decoration: none;
      position: relative;
      cursor: pointer;
      background-color: #78003F;
      width: calc(50% - 1px);
      border-right: 1px solid #ffffff54;

      &.active {
        background-color: #E64164;

        &:before {
          background-image: linear-gradient(45deg, #E64164 50%, transparent 50%);
          position: absolute;
          left: 50%;
          content: '';
          display: block;
          height: 10px;
          width: 10px;
          border-bottom: 2px solid #E64164;
          border-left: 2px solid #E64164;
          transform: translateY(37px) rotate(-45deg);
          z-index: 9;
        }
      }

      .tab-text {
        text-align: center;
        padding: 12px;
        font-weight: 600;
        color: white;
      }
    }
  }

  .client-zone-tab-content {
    position: relative;

    &.active {
      display: block;
    }
  }
}
